'use client';

import { Component, createContext } from 'react';
import { ThemeProvider } from 'styled-components';

//* Helpers
import themes from '@/styles/theme';
import Variables from '@/styles/variables';
import Typography from '@/styles/typography';
import HelperClass from '@/styles/helperClass';
// import FormStyle from '@/styles/pages/FormStyle';

const UIContext = createContext(null);

class UIProvider extends Component {
	//! States
	state = {
		winWidth: 1920,
		winHeight: 1080,
		screenSizes: {},
		preloader: false,
		highComponent: null,
		popupIsOpen: false,
		thankYouIsShow: false,
		popupComponent: null,
		popupProps: {},
		isBurgerMenuOpen: false,
		isTouch: false,
		updatePageResizeState: false,
	};

	//! Methods
	methods = {
		addHighComponent: this.addHighComponent.bind(this),
		removeHighComponent: this.removeHighComponent.bind(this),
		disableDomScroll: this.disableDomScroll.bind(this),
		enableDomScroll: this.enableDomScroll.bind(this),
		openPopup: this.openPopup.bind(this),
		showThankYou: this.showThankYou.bind(this),
		closePopup: this.closePopup.bind(this),
		togglePopup: this.togglePopup.bind(this),
		toggleBurgerMenu: this.toggleBurgerMenu.bind(this),
		closeBurgerMenu: this.closeBurgerMenu.bind(this),
		disablePreloader: this.disablePreloader.bind(this),
		isTouchScreen: this.isTouchScreen.bind(this),
		updatePageResize: this.updatePageResize.bind(this),
	};

	//! Update Dynamic Size Render
	updatePageResize(elem) {
		this.setState({ updatePageResizeState: elem || undefined });
	}

	//! Screen Touch
	isTouchScreen() {
		this.setState({
			isTouch: typeof window !== 'undefined' && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0,
		});
	}

	//! Screen Resize
	screenResize = () => {
		this.setState({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		});
	};

	//! Get Media Screen Sizes from Css Variables
	getScreenSizes() {
		const root = getComputedStyle(document.documentElement);

		const screenSizes = {
			desktopSizeXL: parseInt(root.getPropertyValue('--desktopSizeXL')),
			desktopSizeL: parseInt(root.getPropertyValue('--desktopSizeL')),
			desktopSizeM: parseInt(root.getPropertyValue('--desktopSizeM')),
			desktopSizeS: parseInt(root.getPropertyValue('--desktopSizeS')),
			tabletSize: parseInt(root.getPropertyValue('--tabletSize')),
			tabletSizeS: parseInt(root.getPropertyValue('--tabletSizeS')),
		};

		this.setState({ screenSizes });
	}

	//! Open Popup
	openPopup(popupComponent = null, popupProps = {}) {
		this.disableDomScroll();

		this.setState({
			popupIsOpen: true,
			popupComponent,
			popupProps,
		});
	}

	showThankYou(value) {
		this.setState({
			thankYouIsShow: value,
		});
	}

	//! Close Popup
	closePopup() {
		this.enableDomScroll();

		this.setState({
			popupIsOpen: false,
			popupComponent: null,
		});
	}

	//! Toggle Popup
	togglePopup() {
		this.setState({
			popupIsOpen: !this.state.popupIsOpen,
		});
	}

	//! Toggle Burger Menu
	toggleBurgerMenu() {
		this.setState({ isBurgerMenuOpen: !this.state.isBurgerMenuOpen });
	}

	//! Close Burger Menu
	closeBurgerMenu() {
		this.state.isBurgerMenuOpen && this.setState({ isBurgerMenuOpen: false });
	}

	//! Disable Preloader
	disablePreloader() {
		this.setState({ preloader: false });
	}

	//! Disable DOM Scroll
	disableDomScroll() {
		// document.querySelector('html').classList.add('hide-scroll')

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

		//! if any scroll is attempted, set this to the previous value
		window.onscroll = function (e) {
			e.preventDefault();
			window.scrollTo(scrollLeft, scrollTop);
		};
	}

	//! Enable DOM Scroll
	enableDomScroll() {
		// document.querySelector('html').classList.remove('hide-scroll');

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

		window.onscroll = function () {};
		window.scrollTo(scrollLeft, scrollTop);
	}

	//! Add High Component
	addHighComponent(component = null) {
		this.setState({
			highComponent: component,
		});
	}

	updateHighComponentProps(props) {
		const Comp = this.state.component;
		Comp.props = props;

		this.setState({
			highComponent: Comp,
		});
	}

	//! Remove High Component
	removeHighComponent() {
		this.setState({
			highComponent: null,
		});
	}

	//! Component Did Mount
	componentDidMount() {
		this.setState({
			...this.state,
		});

		window.addEventListener('resize', this.screenResize);
		this.getScreenSizes();
		this.screenResize();
		this.isTouchScreen();
	}

	//! Component Will Unmount
	componentWillUnmount() {
		window.addEventListener('resize', this.screenResize);
		document.removeEventListener('keydown', this.escKeydown, false);
	}

	render() {
		return (
			<UIContext.Provider value={{ ...this.state, ...this.methods }}>
				<ThemeProvider theme={themes}>
					<Variables />
					<HelperClass />
					{/* <FormStyle /> */}
					<Typography
						isBurgerMenuOpen={this.state.isBurgerMenuOpen}
						popupIsOpen={this.state.popupIsOpen}
					/>

					{this.props.children}
				</ThemeProvider>
			</UIContext.Provider>
		);
	}
}

export default UIProvider;
export const UIConsumer = UIContext.Consumer;
