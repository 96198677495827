'use client';

import { useState, createContext, useCallback, useMemo } from 'react';

//* Context
import withLanguageContext from '../consumerHOC/LanguageConsumer';

//* Helpers
import { api } from '@/helpers';

const DataContext = createContext(null);
export const DataConsumer = DataContext.Consumer;

const DataProvider = (props) => {
	const [state, setState] = useState({
		...props.newData,
		prevRoute: null,
		partners: {
			items: [],
			title: '',
		},
		currency: null,
		exchangeRate: null
	});

	const setTargetCurrency = (val) => {
		setState((prev) => ({ ...prev, currency: val }));
	}

	const setExchangeRate = (val) => {
		setState((prev) => ({ ...prev, exchangeRate: val }));
	}

	const enableLoading = () => {
		this.setState({ loading: true });
	};

	const disableLoading = () => {
		setState((prev) => ({ ...prev, loading: false }));
	};

	const setPartners = (partners) => {
		setState((prev) => ({ ...prev, partners: partners }));
	};

	const contactUs = async (data) => {
		return await api.post('reserve-call', data);
	};

	const getGlobalData = useMemo(() => state.globalData?.[props.selectedLang], [props.selectedLang, state]);

	const methods = {
		getGlobalData,
		enableLoading,
		disableLoading,
		setPartners,
		contactUs,
		setTargetCurrency,
		setExchangeRate
	};

	return <DataContext.Provider value={{ ...state, ...methods }}>{props.children}</DataContext.Provider>;
};

export default withLanguageContext(DataProvider, ['selectedLang']);
